.container {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
}

.content {
  composes: shadow from global;
  composes: backgroundColor from global;
  width: 400px;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contentInner {
  padding-top: 100px;
  padding-left: env(safe-area-inset-left);
}

.photo {
  flex: 1;
  background-color: #eee;
  background-image: url('/background.jpeg');
  background-size: cover;
  background-position: center;
}

.logoContainer {
  margin-bottom: 40px;
  text-align: center;
}

.logo {
  max-height: 100px;
  max-width: 60%;
}

.mainLogoContainer {
  margin-top: 100px;
  text-align: center;
  opacity: 0.4;
  transition: all 0.5s ease;
}

.mainLogoContainer:hover {
  opacity: 1;
}

.mainLogo {
  height: 25px;
}

@media (max-width: 500px) {
  .content {
    align-items: center;
    min-width: 100%;
  }

  .logoContainer {
    margin-bottom: 80px;
    display: ruby-text;
  }

  .mainLogoContainer {
    margin-top: 80px;
  }
}
